

import {LoggedInComponentBase} from '@/components/base/loggedInComponentBase';
import {Component, Prop} from 'vue-property-decorator';
import {RadioCommercialItem} from '@/models/radioCommercials/radioCommercialItem';
import {format} from 'date-fns';

@Component
export default class RadioCommercialCard extends LoggedInComponentBase {

  @Prop() radioCommercialItem!: RadioCommercialItem;

  /**
   * Need to cast the <audio> element to HTMLMediaElement so Typescript doesn't complain
   * about missing properties like `pause` or `currentTime` etc.
   */
  get audioPlayer(): HTMLMediaElement {
    return this.$refs.audioPlayer as HTMLMediaElement;
  }

  get formattedPostDate(): string {
    const postDate = new Date(this.radioCommercialItem.postDate);
    return `${format(postDate, 'd')}/${format(postDate, 'M')}/${format(postDate, 'yyyy')}`;
  }

  dialogClosed() {
    this.audioPlayer.pause();
    this.audioPlayer.currentTime = 0;
  }
}

