
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { Component, Prop, Vue } from 'vue-property-decorator';

// https://github.com/kaorun343/vue-youtube-embed
// TODO: Should be moved to main.ts with other global embeds.
import VueYouTubeEmbed, { YouTubePlayer } from 'vue-youtube-embed';
import { AssetListItem } from '@/models/assets/assetListItem';

Vue.use(VueYouTubeEmbed, { global: false });

@Component({
  components: {
    youtube: YouTubePlayer
  }
})
export default class TvcCard extends LoggedInComponentBase {
  @Prop({ required: true })
  public tvc!: AssetListItem;
  public modal: boolean = false;
  // eslint-disable-next-line
  private player: any;

  public get tvcImage() {
    return `https://img.youtube.com/vi/${this.tvc.videoCode}/0.jpg`;
  }

  public dialogClosed() {
    this.player?.stopVideo();
  }

  public ready(event: Event) {
    this.player = event.target;
  }
}
